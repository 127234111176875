// Language data store

export default class LanguageModel {
  constructor(options) {
    this.enterKey = 'Entrer'
    this.shiftKey = 'Shift'
    this.ok = 'Suivant'
    this.continue = 'Continue'
    this.skip = 'Passer'
    this.pressEnter = 'Appuyer sur :enterKey'
    this.multipleChoiceHelpText = 'Choisissez autant de réponses que vous voulez'
    this.multipleChoiceHelpTextSingle = 'Choisissez une seule réponse'
    this.otherPrompt = 'Autre'
    this.placeholder = 'Entrer votre réponse ici...'
    this.submitText = 'Appuyer pour découvrir votre niveau'
    this.longTextHelpText = ':shiftKey + :enterKey pour retourner à la ligne'
    this.prev = 'Précédent'
    this.next = 'Suivant'
    this.percentCompleted = ':percent% complété(s)'
    this.invalidPrompt = 'Une limite de 2 à 6 joueurs est mise en place. Pour toute demande particulière merci de nous contacter directement'
    this.thankYouText = 'Merci !'
    this.successText = 'Vos réponses ont été envoyées'
    this.ariaOk = 'Appuyer pour continuer'
    this.ariaRequired = 'Cette étape est obligatoire'
    this.ariaNext = 'Étape suivante'
    this.ariaPrev = 'Étape précédente'
    this.ariaSubmitText = 'Appuyer pour découvrir votre niveau'
    this.ariaMultipleChoice = 'Appuyez sur :letter pour sélectionner'
    this.ariaTypeAnswer = 'Entrez votre réponse ici...'
    this.errorAllowedFileTypes = 'Réponse invalide. Réponse valide sous le format: :fileTypes.'
    this.errorMaxFileSize = 'Fichier(s) trop volumineux. La taille maximale est de: :size.'
    this.errorMinFiles = 'Pas assez de fichier importé. Minimum autorisé: :min.'
    this.errorMaxFiles = 'Trop de fichiers importés. Maximum autorisé: :max.'

    Object.assign(this, options || {})
  }

  /**
   * Inserts a new CSS class into the language model string to format the :string
   * Use it in a component's v-html directive: v-html="language.formatString(language.languageString)"
   */
  formatString(string, replacements) {
    return string.replace(/:(\w+)/g, (match, word) => {
      if (this[word]) {
        return '<span class="f-string-em">' + this[word] + '</span>'
      } else if (replacements && replacements[word]) {
        return replacements[word]
      }
      
      return match
    })
  }

  formatFileSize(bytes) {
    const
      units = ['B', 'kB', 'MB', 'GB', 'TB'],
      i = bytes > 0 ? Math.floor(Math.log(bytes) / Math.log(1024)) : 0
      
    return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + units[i];
  }
}


